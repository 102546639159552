
import { getObjectItem, setObjectItem } from '@/utils/LocalStorageUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component
export default class MapSettingsModal extends mixins(AppName) {
  @Prop() width!: number;
  public mapTagValues: { [key: string]: { value: string; label: string }[] } = {
    milk: [
      { value: 'age', label: 'Milk Age' },
      { value: 'score', label: 'Milk Score' }
    ],
    fuel: [
      { value: 'remaining', label: 'Days Remaining' },
      { value: 'percentFull', label: 'Percent Full' }
    ],
    water: [
      { value: 'currentUsage', label: 'Current Usage' },
      { value: 'usageToday', label: 'Usage Today' },
      { value: 'usageYesterday', label: 'Usage Yesterday' }
    ],
    feed: [
      { value: 'remaining', label: 'Days Remaining' },
      { value: 'percentFull', label: 'Percent Full' }
    ]
  };
  public selectedMapTagValue: null | string = null;

  mounted() {
    if (this.selectedMapTagValue === null) {
      this.selectedMapTagValue = this.mapTagValues[
        this.getAppName(false)
      ][0].value;
    }
  }

  created() {
    const mapSettings = getObjectItem(`${this.getAppName(false)}MapSettings`);
    if (mapSettings) {
      this.selectedMapTagValue = mapSettings.mapTag;
    }
  }

  public saveSettings() {
    const mapSettings: any = { mapTag: this.selectedMapTagValue };
    setObjectItem(`${this.getAppName(false)}MapSettings`, mapSettings);
    this.$root.$emit('mapSettingsUpdated');
    this.closeMapModal();
  }

  public closeMapModal() {
    this.$root.$emit('bv::hide::modal', 'mapSettingsModal', '#btnHide');
  }
}
