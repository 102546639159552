
/// <reference types="@types/googlemaps" />
import MapSettingsModal from '@/components/modal/MapSettingsModal.vue';
import BoundingBox from '@/services/api/models/BoundingBox';
import { Map } from '@/services/api/models/responses/LoginResponse';
import { trackEvent } from '@/services/Mixpanel';
import ProductEntityState from '@/store/models/ProductEntityState';
import User from '@/store/modules/User';
import { getBootstrapColourHex } from '@/utils/Constants';
import {
  getObjectItem,
  removeUserItem,
  setObjectItem
} from '@/utils/LocalStorageUtils';
import {
  formatLastPickup,
  formatMilkScore,
  formatMilkVolume
} from '@/utils/TableFormatters';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from './mixin/AppName.vue';
import ConstantsMixin from './mixin/Constants.vue';
import { encodeSVG } from '@/utils/SvgUtils';
import { getMarkerSvgString } from '@/utils/MapUtils';

interface Markers {
  [key: string]: google.maps.Marker;
}

const LEVNO_ORANGE = 'rgb(230, 115, 9)';
const LEVNO_NAVY = 'rgb(38, 40, 62)';
const LEVNO_FEEDCOLOUR = 'rgb(51,149,177)';
const LEVNO_FUELCOLOUR = 'rgb(51,177,166)';

const styles: Record<string, google.maps.MapTypeStyle[]> = {
  default: [],
  silver: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }]
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f5f5' }]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#bdbdbd' }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#dadada' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{ color: '#e5e5e5' }]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{ color: '#eeeeee' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9e9e9e' }]
    }
  ]
};

// /**
//  * Use this Vue component like (center on Palmerston North,
//  * with a timeout of 350 ms on falling edge of user
//  * interaction) below:
//  *
//  *  <levno-google-map
//  *      :center-lat="-40.3584"
//  *      :center-lon="175.6067"
//  *      :debounce-timeout-ms="350"
//  *      :default-zoom="8"
//  *  ></levno-google-map>
//  *
//  * This Vue component emits the following events:
//  *
//  *  - mapDidChange (with bounding box)
//  *
//  *  - mapDidSelectMarker (with selected marker)
//  */

@Component({
  components: {
    MapSettingsModal
  },
  methods: {
    formatLastPickup,
    formatMilkScore,
    formatMilkVolume
  }
})
export default class GoogleMap extends mixins(AppName, ConstantsMixin) {
  /**
   * the markers that are currently displayed on the map.
   * don't mutate this array directly, instead use setMarkers()
   */
  public markers: Markers = {};
  /**
   * our Google Map control, configured in initMap()
   */
  public map!: google.maps.Map;
  /**
   * when true emit the mapDidSearch event at the end of
   * each user interaction
   */
  public autoSearch = false;
  /**
   * use this variable only when autoSearch is false to
   * determine whether the user can trigger a manual
   * mapDidSearch event
   */
  public readyToSearch = true;
  private timeout!: number | null;

  public infoWindow!: google.maps.InfoWindow;

  public colour = LEVNO_ORANGE;
  // public enableClustering = false;
  public boundsOutOfDate = false;
  public autoSearchAutoDisabled = false;
  public firstLoad = true;
  /**
   * used by the debounce in mapDidChange() to ensure the
   * mapDidChange event is emitted only on the falling edge
   * of user interaction.
   */
  @Prop({ default: 300 }) debounceTimeoutMs!: number;
  /**
   * starting center of the map, defaults
   * to all of New Zealand
   */
  @Prop({ default: -41.2891 }) centerLat!: number;
  @Prop({ default: 174.555 }) centerLon!: number;
  /**
   * starting zoom level to fit all of New Zealand
   */
  @Prop({ default: 5 }) defaultZoom!: number;

  @Prop() selectedEntityState!: ProductEntityState | null;
  @Prop() productType!: string;
  @Prop() loading!: boolean;
  @Prop() sidebarActive!: boolean;
  @Prop() width!: number;
  @Prop() mapHeight?: number;
  @Prop() hideSettings!: boolean;
  @Prop() specificMapLocation!: boolean;
  @Prop({ default: false }) isSidebarMap!: boolean;

  @Watch('autoSearch')
  toggleAutoSearch(value: boolean) {
    if (value && !this.sidebarActive) {
      this._mapDidChange();
      this.readyToSearch = true;
    }
  }

  @Watch('sidebarActive')
  sidebarToggled(value: boolean) {
    if (value) {
      this.autoSearchAutoDisabled = this.autoSearch;
      this.autoSearch = false;
    } else {
      if (this.autoSearchAutoDisabled) {
        this.autoSearch = true;
      }
    }
  }

  @Watch('centerLat')
  @Watch('centerLon')
  propMapBoundsChanged() {
    this.setUpMap(false);
  }

  mounted() {
    this.initMap();
  }

  created() {
    this.$root.$on('resetMap', () => {
      this.resetMap();
    });
    this.$root.$on('orgIndexChanged', () => {
      this.setUpMap(false);
    });
  }
  // public toggle() {
  //   this.enableClustering = !this.enableClustering;
  //   this.$emit('toggleClustering', this.enableClustering);
  //   this._mapDidChange();
  // }

  /**
   * This function should be called when this component is
   * mounted to create the Google Map, and set up the event
   * listeners.
   */
  private initMap() {
    const mapElement = document.getElementById(
      `map${this.isSidebarMap ? '-sidebar' : ''}`
    );
    /* https://developers.google.com/maps/documentation/javascript/reference/map */
    let centreLat = this.centerLat;
    let centreLon = this.centerLon;
    let zoom = this.defaultZoom;

    [centreLat, centreLon, zoom] = this.setUpMap(true);
    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(mapElement ?? new Element(), {
      // eslint-disable-next-line no-undef
      center: new google.maps.LatLng(centreLat, centreLon, false),
      zoom: zoom,
      disableDefaultUI: true,
      zoomControl: true
    });
    this.map.setOptions({ styles: styles.silver });

    if (this.map) {
      this.map.addListener('bounds_changed', this.mapDidChange);
    }

    // eslint-disable-next-line no-undef
    this.infoWindow = new google.maps.InfoWindow({
      content: ' ',
      disableAutoPan: true
    });
    // eslint-disable-next-line no-undef
    this.colour = this.getAppColourRGB();
  }

  /**
   * Add a marker to the map. You'll need to provide:
   *
   *  - lat (latitude)
   *  - lon (longitude)
   *  - tag (unique ID for marker -- e.g. site ID)
   *
   * All are required.
   *
   * When the marker is clicked a didSelectMarker event
   * will be emitted from this component with the tag as
   * the argument.
   */
  public addMarker(opts: {
    lat: number;
    lon: number;
    tag: number;
    markerVal: string;
    supplyNum: string;
    isSelected: boolean;
    mapTagValue: string | undefined;
  }) {
    const zIndex = -opts.lat * 10000; //100 - this.getNumber(opts.markerVal) -
    if (this.markers[opts.tag] && opts.mapTagValue) {
      this.markers[opts.tag].setPosition({ lat: opts.lat, lng: opts.lon });
      this.markers[opts.tag].setIcon(this.getMarker(opts));
      if (opts.mapTagValue == 'score') {
        this.markers[opts.tag].setLabel('');
      } else {
        this.markers[opts.tag].setLabel({
          text: opts.markerVal,
          color: 'white',
          fontWeight: '600',
          fontSize: '14px'
        });
      }

      this.markers[opts.tag].setZIndex(zIndex);
    } else if (opts.mapTagValue) {
      const markerOptions = {
        position: { lat: opts.lat, lng: opts.lon },
        map: this.map,
        tag: opts.tag,
        zIndex: zIndex,
        icon: this.getMarker(opts),
        label:
          opts.mapTagValue == 'score'
            ? ''
            : {
                text: opts.markerVal,
                color: 'white',
                fontWeight: '600',
                fontSize: '14px'
              }
      } as google.maps.ReadonlyMarkerOptions;
      // eslint-disable-next-line no-undef
      const m = new google.maps.Marker(markerOptions);
      const mouseout = m.addListener('mouseout', () => {
        if (
          !this.selectedEntityState ||
          opts.tag !== this.selectedEntityState?.id
        ) {
          this.infoWindow.close();
        }
      });
      m.addListener('click', () => {
        mouseout.remove();
        this.didSelectMarker(opts.tag, 'marker');
      });
      m.addListener('mouseover', () => {
        if (
          !this.selectedEntityState ||
          opts.tag !== this.selectedEntityState?.id
        ) {
          if (this.productType == 'milk') {
            this.infoWindow.setContent('Supply Number: ' + opts.supplyNum);
            this.infoWindow.open(this.map, m);
          }
        }
      });

      this.markers[opts.tag] = m;
    }
  }

  private zoomToMarkers() {
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();

    // extend the bounds to include each marker
    Object.values(this.markers).forEach((marker: google.maps.Marker) => {
      const position = marker.getPosition();
      if (position) {
        bounds.extend(position);
      }
    });

    // zoom the map to include all markers
    this.map.fitBounds(bounds);
  }

  public setUpMap(initialise: boolean) {
    let centreLat = this.centerLat;
    let centreLon = this.centerLon;
    let zoom = this.defaultZoom;
    let mapBounds = getObjectItem(`${this.productType}MapBounds`);
    if (this.specificMapLocation) {
      this.$emit('filterByMap', true);
    } else {
      if (mapBounds) {
        this.$emit('filterByMap', true);
        centreLat = (mapBounds.southWest.lat + mapBounds.northEast.lat) / 2;
        centreLon = (mapBounds.southWest.lon + mapBounds.northEast.lon) / 2;
        zoom = mapBounds.zoom;
      } else {
        mapBounds = User._token?.orgs[User._orgIndex].customSettings
          ?.map as Map;
        if (mapBounds) {
          centreLat = mapBounds.defaultLatitude;
          centreLon = mapBounds.defaultLongitude;
          zoom = mapBounds.defaultZoom;
        }
      }
    }

    if (!initialise) {
      this.map.setCenter(
        // eslint-disable-next-line no-undef
        new google.maps.LatLng(centreLat, centreLon, false)
      );
      this.map.setZoom(zoom);
      this._mapDidChange();
    }
    return [centreLat, centreLon, zoom] as const;
  }

  public resetMap() {
    const mapBounds = User._token?.orgs[User._orgIndex].customSettings
      ?.map as Map;
    if (mapBounds) {
      this.$emit('filterByMap', true);
      this.map.setCenter(
        // eslint-disable-next-line no-undef
        new google.maps.LatLng(
          mapBounds.defaultLatitude,
          mapBounds.defaultLongitude,
          false
        )
      );
      this.map.setZoom(mapBounds.defaultZoom);
    } else {
      // eslint-disable-next-line no-undef
      this.map.setCenter(new google.maps.LatLng(-41.2891, 174.555, false));
      this.map.setZoom(5);
    }
    removeUserItem(`${this.productType}MapBounds`);
    removeUserItem(`${this.productType}MapHeight`);
    trackEvent('User removed saved map bounds');

    this.$bvToast.toast('Your map bounds have been reset', {
      title: 'Map Bounds Saved',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
  }

  /**
   * Remove all the markers from the map.
   */
  public clearMarkers() {
    Object.values(this.markers).forEach(marker => marker.setMap(null));
    this.markers = {};
  }

  private didSelectMarker(tag: number, from: string) {
    if (from == 'marker') {
      setTimeout(() => {
        this.$emit('mapDidSelectMarker', tag);
      }, 250);
    } else {
      this.$emit('mapDidSelectMarker', tag);
    }
  }

  public setDetailCard(n: google.maps.Marker) {
    this.infoWindow.close();
    // this.detailCard.close();
    // if (
    //   (this.selectedEntityState?.vats?.length &&
    //     this.selectedEntityState?.vats?.length > 0) ||
    //   (this.selectedEntityState?.tanks?.length &&
    //     this.selectedEntityState?.tanks?.length > 0) ||
    //   this.loading
    // ) {
    //   // there is potential for the infinite spinning wheel if a product actually is null
    //   // as the following code will run if the selectedEntityState is null as I assume
    //   // this as loading for the first time
    //   this.detailCard.setContent(this.$refs.infoDetail as Node);
    // } else {
    //   this.detailCard.setContent('No information to display');
    // }

    // this.detailCard.open(this.map, n);
    // this.detailOpen = true;
  }

  /**
   * Called by Google Maps when the bounds of the map
   * have changed (e.g. by a zoom or pan gesture).
   *
   * This function debounces the _mapDidChange function
   * to ensure this component emits the mapDidChange
   * event only on the falling edge of map interactions.
   */
  public mapDidChange() {
    if ((this.autoSearch && !this.sidebarActive) || this.firstLoad) {
      this.firstLoad = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this._mapDidChange();
      }, this.debounceTimeoutMs);
    } else {
      this.boundsOutOfDate = true;
    }
    this.readyToSearch = true;
  }

  public mobileBoundRefresh() {
    this.$bvToast.toast('Table data has been updated based on map bounds', {
      title: 'Table Data Updated',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false
    });
    this.$emit('filterByMap', true);
    this._mapDidChange();
  }

  /**
   * Called by the mapDidChange function on a falling
   * edge of user interaction to emit the mapDidChange
   * event, with the bounding box coordinates.
   */
  public _mapDidChange() {
    this.readyToSearch = false;
    this.boundsOutOfDate = false;

    this.$emit('mapDidChange', this.getLatLng());
  }

  public saveBounds() {
    const boundsToSave = {
      ...this.getLatLng(),
      zoom: this.map.getZoom()
    };

    setObjectItem(`${this.productType}MapBounds`, boundsToSave);
    setObjectItem(`${this.productType}MapHeight`, this.mapHeight);
    trackEvent('User saved map bounds');

    this.$bvToast.toast(
      'Your current map bounds have been saved to your browser',
      {
        title: 'Map Bounds Saved',
        toaster: 'b-toaster-bottom-center',
        solid: true,
        append: false
      }
    );
  }

  public getLatLng(): BoundingBox | undefined {
    const bounds = this.map.getBounds();

    if (bounds) {
      const boundingBox = {
        southWest: {
          lat: bounds.getSouthWest().lat(),
          lon: bounds.getSouthWest().lng()
        },
        northEast: {
          lat: bounds.getNorthEast().lat(),
          lon:
            bounds.getNorthEast().lng() < 0 &&
            !(bounds.getSouthWest().lng() < 0)
              ? 180 + (180 - Math.abs(bounds.getNorthEast().lng()))
              : bounds.getNorthEast().lng()
        }
      };
      return boundingBox;
    }
  }

  public getMarker(options: {
    isSelected: boolean;
    markerVal?: string;
    mapTagValue?: string;
  }) {
    if (this.isApp(this.PRODUCT_TYPE_MILK) && options.mapTagValue == 'score') {
      return {
        url: encodeSVG(
          getMarkerSvgString(
            options.isSelected,
            options.markerVal ? options.markerVal.toLowerCase() : ''
          )
        ),

        scaledSize: options.isSelected // eslint-disable-next-line no-undef
          ? new google.maps.Size(56, 65) // eslint-disable-next-line no-undef
          : new google.maps.Size(40, 32)
      };
    } else {
      const isSelected = options.isSelected;
      const color = isSelected ? this.colour : LEVNO_NAVY;

      return {
        // other icons can be found here: https://orioniconlibrary.com/icon/pointer-690
        path:
          'M3.2,6.3h91.4c1.3,0,2.3,1.3,2.3,2.8v37.2c0,1.6-1,2.8-2.3,2.8H60.7c-0.6,0-1.2,0.3-1.6,0.8L48.8,62.7L38.7,50.2c-0.4-0.5-1-0.8-1.6-0.8H3.2C2,49.4,1,48.1,1,46.6V9.1C0.9,7.5,2,6.3,3.2,6.3z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#fff',
        strokeWeight: 1.5,
        strokeOpacity: 1,
        scale: 0.6,
        // eslint-disable-next-line no-undef
        labelOrigin: new google.maps.Point(48, 29),
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(60, 64)
      };
    }
  }

  public getColour(options: { isSelected: boolean; markerVal?: string }) {
    // if isSelected arg isn't passed we'll set to false
    const isSelected = options.isSelected;
    let color = getBootstrapColourHex('primary');
    if (
      this.isApp(this.PRODUCT_TYPE_MILK) &&
      options.markerVal &&
      getBootstrapColourHex(options.markerVal.toLowerCase())
    ) {
      color = getBootstrapColourHex(options.markerVal.toLowerCase());
    } else if (!this.isApp(this.PRODUCT_TYPE_MILK) && isSelected) {
      color = this.getAppColour(false);
    }
    return color.replace('#', '');
  }

  private getSecondMarker(options: { isSelected: boolean }) {
    // if isSelected arg isn't passed we'll set to false
    const isSelected = options.isSelected;

    const color = isSelected ? this.colour : LEVNO_NAVY;

    return {
      // other icons can be found here: https://orioniconlibrary.com/icon/pointer-690
      path:
        'M5.2,6.3H116c2.4,0,4.3,1.9,4.3,4.3v34.3c0,2.4-1.9,4.3-4.3,4.3H75.9c-1.1,0-2.2,0.5-3,1.3L61.6,61.7c-0.6,0.6-1.5,0.6-2.1,0L48.4,50.6c-0.8-0.8-1.9-1.3-3-1.3H5.2c-2.4,0-4.3-1.9-4.3-4.3V10.5C0.9,8.2,2.9,6.3,5.2,6.3z',
      fillColor: color,
      fillOpacity: 1,
      strokeColor: '#fff',
      strokeWeight: 2.4,
      scale: 0.6,
      // eslint-disable-next-line no-undef
      labelOrigin: new google.maps.Point(60, 28),
      // eslint-disable-next-line no-undef
      anchor: new google.maps.Point(60, 64)
    };
  }

  public getNumber(value: string): number {
    return +value.split(' ')[0];
  }

  public showMapSettingsModal() {
    this.$root.$emit('bv::show::modal', 'mapSettingsModal', '#btnShow');
  }
}
